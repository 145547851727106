import React, { useState, useEffect } from "react";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify'
import Casinomodel from "./Casinomodel";

window.pnlView = 1;
window.daypnl = 3;
toast.configure()

export default function CasinoReport(props) {

	//const date = new Date().toLocaleDateString();
	const [time, setDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [select, setselect] = useState(0)
	const [profitLoss, setprofitLoss] = useState([])
	const [eventType, seteventType] = useState('0');
	const [netpl, setnetpl] = useState(0);
	const [casinoModel, setcasinoModel] = useState(false);
	const [click, setclick] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(20);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = profitLoss.slice(indexOfFirstPost, indexOfLastPost);

	useEffect(() => {
		var timerID = setInterval(() => tick(), 1000);
		return function cleanup() {
			clearInterval(timerID);
		};
	}, []);

	function tick() {
		setDate(moment().format('YYYY-MM-DD HH:mm:ss'));
	}


	const handleSlip = (index) => {
		if (click === index) {
			setclick(-1);
		}
		else {
			setclick(index);
		}

	}
	const changeEvent = (e) => {
		var selectBox = document.getElementById("sportsevent");
		var selectedValue = selectBox.options[selectBox.selectedIndex].value;

		if (selectedValue == 0) {
			seteventType('0');
		}
		else if (selectedValue == 1) {
			seteventType('1');
		}
		else if (selectedValue == 2) {
			seteventType('2');
		}
		else if (selectedValue == 4) {
			seteventType('4');
		}

		window.pnlView = 1;
		getpnl(selectedValue);



	}

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber)
	};

	const getpnl = (pageno) => {

		var start = '';
		var end = '';

		if (window.daypnl === 3) {
			start = sDate;
			end = eDate;
		}
		else if (window.daypnl === 1) {
			var now = moment();
			start = now.startOf('day').format("YYYY-MM-DD");
			end = now.endOf('day').format("YYYY-MM-DD");

		}
		else if (window.daypnl === 2) {
			var now = moment().subtract(1, 'days');
			start = now.startOf('day').format("YYYY-MM-DD");
			end = moment().endOf('day').format("YYYY-MM-DD");
		}

		axios.post('https://luckybdt.live/clientapi/new_get_casino_report', {
			id: props.userid,
			token: props.utoken,
			start_date: start,
			end_date: end
		})
			.then(result => {
				//console.log(result.status,result.data,'result');
				if (result.data.data.length == 0) {
					toast.warn('There are no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
					if (eventType !== '0') {
						seteventType('0');
					}
				}

				if (result.status === 200) {
					setprofitLoss(result.data.data);

					var netSum = 0;
					result.data.data.map((item, index) => {

						netSum = netSum + (parseFloat(item.payout) - parseFloat(item.staked));
					});
					setnetpl(netSum.toFixed(2));

				}
			}

			).catch(e => {
				//setIsError(true);
			});

	}
	const casinoWindowOpen = (val) => {
		setcasinoModel(val)
	}

	return (
		<div>
			{casinoModel &&
				<Casinomodel casinoWindowOpen={casinoWindowOpen} reportData={currentPosts} />}

			<div class="col-left">
				<div class="sub_path">
					<div class="path">

						<ul>
							<li class="path-last"><a    >My Account</a></li>
						</ul>
					</div>
					<ul id="path-pop" class="path-pop">
					</ul>
					<ul class="sports-side-menu-list">
						<li   ><a id="sub_menu_detail" href="/exchange/play/myprofile">My Profile</a></li>
						<li   ><a id="sub_menu_summary" href="/exchange/play/balance">Balance Overview</a></li>
						<li   ><a id="sub_menu_accountCashStatement_log" href="/exchange/play/AccountStatement">Account Statement</a></li>
						<li   ><a id="sub_menu_my_bets" href="/exchange/play/mybets" class="select">My Bets</a></li>
						<li   ><a id="sub_menu_activity_log" href="/exchange/play/activitylog">Activity Log</a></li>
						<li   ><a id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
					</ul>
				</div>
			</div>
			<div class="centar-content report">
				<div id="loading" class="loading-wrap" style={{ display: 'none' }}>
					<ul class="loading">
						<li   ><img src={Loading} alt="loading" /></li>
						<li   >Loading...</li>
					</ul>
				</div>
				<div id="message" class="message-content success">
					<a class="close-button">Close</a>
					<p></p>
				</div>
				<div id="message" class="message-content success">
					<a class="close-button"  >Close</a>
					<p>Your cash out was successful !</p>
				</div>
				<h2>My Bets</h2>
				<ul style={{ display: 'flex', width: '55%' }} class="tab col3">
					<li   ><a href="/exchange/play/mybets"  >Current Bets</a></li>
					<li   ><a href="/exchange/play/bethistory" >Bets History</a></li>
					<li   ><a href="/exchange/play/ProfitLoss" >Profit &amp; Loss</a></li>
					<li   ><a class="select" href="/exchange/play/casinoProfitLoss">Casino Report</a></li>
				</ul>
				<div class="white-wrap">
					<h3>Profit &amp; Loss - Main wallet</h3>
					<ul class="acc-info">
						<li class="user">{props.userid}</li>
						<li class="time">{time}</li>
					</ul>
					<ul class="report-tab-wrap" >
						<li onClick={() => { setselect(0) }} className='report-tab' id="reportType_exchange" data-reporttabtype="0">
							Exchange
						</li>
						<li onClick={() => { setselect(2) }} className="report-tab select" id="reportType_sportsBook" data-reporttabtype="2">
							Casino
						</li>
						<li onClick={() => { setselect(3) }} className={`report-tab ${(select === 3) ? "select" : "null"}`} id="reportType_bookMaker" data-reporttabtype="3">
							Sportsbook
						</li>
						<li onClick={() => { setselect(4) }} className={`report-tab ${(select === 4) ? "select" : "null"}`} id="reportType_bPoker" data-reporttabtype="4">
							BPoker
						</li>
						<li onClick={() => { setselect(5) }} className={`report-tab ${(select === 5) ? "select" : "null"}`} id="reportType_binary" data-reporttabtype="5">
							Binary
						</li>
					</ul>
					<div class="function-wrap">
						<ul class="input-list">
							<div id="statusCondition" style={{ display: 'none' }}>
								<li   ><label>Bet Status:</label></li>
								<li   >
									<select name="betStatus" id="betStatus">
									</select>
								</li>
							</div>
							<li   ><label>Period</label></li>
							<li   >

								<ul class="input-list" style={{ display: 'inline-flex' }}>

									<DatePicker
										selectsStart
										dateFormat="yyyy-MM-dd"
										selected={startDate}
										placeholderText="YYYY-MM-DD"
										className="cal-input"
										onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
									/>
									<input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="00:00" maxlength="5" />

									<DatePicker
										selectsEnd
										dateFormat="yyyy-MM-dd"
										placeholderText="YYYY-MM-DD"
										selected={endDate}
										onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
										className="cal-input"
									/>
									<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="23:59" maxlength="5" />


								</ul>


							</li>

							<li style={{ display: 'none' }}>(TimeZone:IST)</li>
						</ul>
						<ul class="input-list">
							<li   ><a id="today" onClick={() => { window.daypnl = 1; window.pnlView = 1; getpnl(eventType); }} class="btn">Just For Today</a></li>
							<li   ><a id="yesterday" onClick={() => { window.daypnl = 2; window.pnlView = 1; getpnl(eventType); }} class="btn">From Yesterday</a></li>
							<li style={{ display: 'none' }}><a id="last7days" class="btn">Last 7 days</a></li>
							<li style={{ display: 'none' }}><a id="last30days" class="btn">Last 30 days</a></li>
							<li style={{ display: 'none' }}><a id="last2months" class="btn">Last 2 Months</a></li>
							<li style={{ display: 'none' }}><a id="last3months" class="btn">Last 3 Months</a></li>
							<li   ><a id="getPL" onClick={() => { window.daypnl = 3; window.pnlView = 1; getpnl(eventType); }} class="btn-send">Get P &amp; L</a></li>
						</ul>
					</div>
					{profitLoss.length == 0 && <div id="noReportMessage"><p>Casino Profit &amp; Loss enables you to review the bets you have placed. <br />
						Specify the time period during which your bets were placed.</p>
						<p>Casino Profit &amp; Loss is available online for the past 2 months.</p></div>}
				</div>



				{profitLoss.length > 0 && <div id="report" data-report="profitAndLossReport">
					<ul id="spotsUl" class="total-show">
						<li id="totalPL">Total P/L: PBU <span className={`${netpl >= 0 ? "" : "red"}`}>{netpl >= 0 ? parseFloat(netpl).toFixed(2) : '(' + Math.abs(netpl).toFixed(2) + ')'}</span></li>
						<li id="sumOfQuery" class="sports-switch">PBU  <span className={`${netpl >= 0 ? "" : "red"}`}>{netpl >= 0 ? parseFloat(netpl).toFixed(2) : '(' + Math.abs(netpl).toFixed(2) + ')'}</span></li>

					</ul>


					<table id="reportTable" class="table01 table-pnl" style={{ display: 'table' }}>
						<tbody>
							<tr>
								<th width="" class="align-L">Date
								</th>

								<th width="18%">Profit/Loss
								</th>
							</tr>

							<tr id="summaryTemplate" style={{ display: 'none' }}>
								<td id="title" class="align-L"></td>
								<td id="startTime"></td>
								<td id="settledDate"></td>
								<td>
									<a id="pl" class="expand-close" href="javascript: void(0);"></a>
								</td>
							</tr>


							{currentPosts.map((item, index) => {

								var NetPl = parseFloat(item.payout) - parseFloat(item.staked);

								let time_s = moment.duration("5:30:00");
								const Newdate = moment(item.start_time).format("YYYY-MM-DD HH:mm:ss");
								const Timezonedate = moment.tz(Newdate, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
								const date = moment(Timezonedate).add(time_s).format("YYYY-MM-DD HH:mm:ss");

								return (

									<React.Fragment key={index}>
										<tr id="summary0" style={{ display: 'table-row' }} >
											<td class="align-L">
												Casino<img className="fromto" src={Transparent} /><strong>LIVE</strong><img className="fromto" src={Transparent} />
												{date}
												{/* {item.start_time} */}
											</td>

											{/* <td id="title" class="align-L"><strong>{item.currTime}</strong></td> */}

											<td>
												<a id="pl0" className={`${click === index ? "expand-open" : "expand-close"}`} onClick={() => { handleSlip(index); }} ><span className={`${NetPl >= 0 ? "" : "red"}`}>{NetPl >= 0 ? parseFloat(NetPl).toFixed(2) : '(' + Math.abs(NetPl).toFixed(2) + ')'}</span></a>
											</td>
										</tr>
										{/* open_In_Details */}
										{click === index &&
											<tr id="casinoDetail0" class="expand" style={{ display: 'table-row' }}>
												<td colspan="4">
													<img class="expand-arrow-R" src={Transparent} />

													<table class="table-commission">
														<tbody><tr>
															<th>Platform
															</th>
															<th>Valid Turnover
															</th>
															<th>Win / Loss
															</th>
															<th>PT/Comm.
															</th>
															<th>Profit/Loss
															</th>
														</tr>
															<tr id="casinoTxTemplate" style={{ display: 'none' }}>
																<td id="vendorPlatform"></td>
																<td id="turnover"></td>
																<td id="payout"></td>
																<td id="taxRebate"></td>
																<td id="balance"></td>
															</tr><tr id="casinoTxRow0" style={{ display: 'table-row' }} class="even">
																<td onClick={() => { casinoWindowOpen(true) }} id="vendorPlatform"><a   >{item.game_type}</a></td>
																<td id="turnover">{item.turn_over}</td>
																<td id="payout" className={`${NetPl >= 0 ? "" : "red"}`}>{NetPl >= 0 ? parseFloat(NetPl).toFixed(2) : '(' + Math.abs(NetPl).toFixed(2) + ')'}</td>
																<td id="taxRebate">0.00</td>
																<td id="balance" className={`${NetPl >= 0 ? "" : "red"}`}>{NetPl >= 0 ? parseFloat(NetPl).toFixed(2) : '(' + Math.abs(NetPl).toFixed(2) + ')'}</td>
															</tr>
															<tr class="casino-grand-total">
																<td>Grand Total
																</td>
																<td id="totalTurnover">{item.turn_over}</td>
																<td id="totalPayout" className={`${NetPl >= 0 ? "" : "red"}`}>{NetPl >= 0 ? parseFloat(NetPl).toFixed(2) : '(' + Math.abs(NetPl).toFixed(2) + ')'}</td>
																<td id="totalTaxRebate">0.00</td>
																<td id="totalBalance" className={`${NetPl >= 0 ? "" : "red"}`}>{NetPl >= 0 ? parseFloat(NetPl).toFixed(2) : '(' + Math.abs(NetPl).toFixed(2) + ')'}</td>
															</tr>
														</tbody></table>

												</td>
											</tr>}


									</React.Fragment>
								)
							})}


						</tbody>
					</table>

					{profitLoss.length > 0 && <div className="pages">
						<Pagination
							prevPageText='prev'
							pageRangeDisplayed={3}
							activePage={currentPage}
							nextPageText='next'
							totalItemsCount={profitLoss.length}
							onChange={handlePageChange}
							itemsCountPerPage={postsPerPage}
							hideFirstLastPages
						/>
					</div>}
					{/* <p class="table-other">Casino P/L is updated here every 5 minute.
	 </p> */}

				</div>}


			</div>
		</div>

	)
}
