import React, { useEffect, useState, Suspense } from 'react';
import LoadingOverlay from "../../mobilecomponent/components/loading-overlay/loading-overlay";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import AppWrapper from "../app-wrapper/app-wrapper";
import Combine from "../../mobilecomponent/Combine";
import CasinoModal from "../../mobilecomponent/CasinoModal";
import { Helmet } from "react-helmet";
import { useMobileSize } from "../../utils/mobile-size/mobile-size";


const CombineWrapper = ({ isLoading, desktopComponent, mobileComponent }) => {
    const { isMobileSize, offSet, mobileWidth } = useMobileSize();

    const [componentLoaded, setComponentLoaded] = useState(true);
    const { location } = useHistory();
    const [isMobilePath, setIsMobilePath] = useState(null);
    const desktopPath = [
        '/exchange/play/myprofile',
        '/exchange/play/balance',
        '/exchange/play/AccountStatement',
        '/exchange/play/mybets',
        '/exchange/play/bethistory',
        '/exchange/play/ProfitLoss',
        '/exchange/play/activitylog'
    ];

    // const cssLoader = () => {
    //     // desktopPath.includes(location.pathname)
    //     if(isMobilePath){
    //         require('../../desktopcomponent/style.css');
    //     } else {
    //         require('../../mobilecomponent/all.css');
    //     }
    // }
    // useEffect(()=>{
    //     cssLoader();
    // },[isMobilePath])

    useEffect(() => {
        const isDesktopPath = desktopPath.includes(location.pathname);
        if (isDesktopPath) {
            setIsMobilePath(false)
            // require('../../desktopcomponent/style.css');
        } else {
            setIsMobilePath(true)
            // require('../../mobilecomponent/all.css');
        }

        setTimeout(() => {
            setComponentLoaded(false)
        }, 100)
    }, [location]);

    // useEffect(() => {
    //     console.log('isMobilePath', isMobilePath)
    // }, [isMobilePath]);

    if (!componentLoaded) {
        return (
            <div className="text-focus-in">
                {isMobilePath ? <>
                    {/*{isMobileSize && (*/}
                    {/*<LoadingOverlay show={isLoading} />*/}
                    <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                    </Helmet>
                    {/*)}*/}

                    {/*<AppWrapper>*/}
                        <LoadingOverlay show={isLoading} />
                        {mobileComponent}
                    {/*</AppWrapper>*/}
                </> : <>

                    {/*<Helmet>*/}
                    {/*    <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"/>*/}
                    {/*</Helmet>*/}
                    {desktopComponent}
                </>}
            </div>
        )
    }
    return null;
}

export default CombineWrapper;
