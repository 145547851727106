import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import newnum from '../images/Banner/Cricket-banner.jpg'
import slotsnum from '../images/Banner/Soccer-banner.jpg'
import superwith from '../images/Banner/Tennis-banner.jpg'


const photos = [



    {
        name: 'photo 8',
        url: [newnum]
    }, {
        name: 'photo 9',
        url: [slotsnum]
    }
    , {
        name: 'photo 10',
        url: [superwith]
    }
]

const settings = {
    dots: false,
    slide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    className: 'slidesN'
}


export default function Carousel() {
    return (
        <React.Fragment>
            <Slider  {...settings} style={{ height: '20vw' }}>
                {photos.map((photo, id) => {
                    return (
                        <div key={id}>
                            <img width="100%" src={photo.url} alt="cric" style={{ height: '20vw' }} />

                        </div>
                    )
                })}
            </Slider>
        </React.Fragment>
    )
}
