import React, {useEffect, useState} from 'react';
import ReactQueryProvider from './serives/react-query/provider';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import AppWrapper from './component/app-wrapper/app-wrapper';
// import 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css';


import Combine from './mobilecomponent/Combine';
import CasinoModal from './mobilecomponent/CasinoModal';
import Desktopindex from './desktopcomponent/Desktopindex.js';
import bgImage from './images/bajie365/side_bg.jpg';
import PullToRefresh from 'react-simple-pull-to-refresh';
import useBreakpoint from "./utils/hooks/breakpoints";


const loc = window.location.href;
const param = loc.split('/');

// require('./mobilecomponent/all.css');


require('./utils/global.css');

export default function App() {
    // const breakpoint = useBreakpoint();
    //
    // useEffect(() => {
    //     if(breakpoint === 'XS' || breakpoint === 'SM'){
    //         r
    //     } else {
    //         require('./desktopcomponent/style.css');
    //     }
    //
    // }, []);

  return (
    <ReactQueryProvider>

        {/*<AppWrapper>*/}
            <Router>
                <Switch>
                    <Route exact path='/' component={Combine}></Route>
                    <Route exact path='/:eventType' component={Combine}></Route>
                    <Route exact path='/:eventType/:subType/:belowType' component={Combine}></Route>
                    <Route exact path='/:subType/:belowType/fullmarket/:eventT/:eventId/:marketId' component={Combine}></Route>
                    <Route path='/game-play/casino' component={CasinoModal} />
                </Switch>
            </Router>
        {/*</AppWrapper>*/}
    </ReactQueryProvider>
  )
}
