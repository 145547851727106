
import React, { useState, useEffect, Suspense } from 'react'
import Footer from './Footer'
import Highlights from './Highlights'
import Header from './Header'
import Loginmobile from './Loginmobile'
import Carousel from './Carousel'
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify'
// import { suid } from 'rand-token';
import axios from 'axios';
import Myprofile from '../mobilecomponent/Myprofile'
import Balanceoverview from '../mobilecomponent/Balanceoverview'
import Mybets from '../mobilecomponent/Mybets'
import Bethistory from '../mobilecomponent/Bethistory'
import ProfitLoss from '../mobilecomponent/ProfitLoss'
import AccountStatement from '../mobilecomponent/AccountStatement'
// import CasinoReport from '../mobilecomponent/CasinoReport'
import ActivityLog from '../mobilecomponent/Activitylog'
// import Myprofile from '../mobilecomponent/'
// import { CasinoList } from './CasinoList'
import Account from './Account'
import Activitylog from '../mobilecomponent/Activitylog'
import { Newfullmarket } from './Newfullmarket'
import ANnouncement from './ANnouncement'
import Navcasino from '../mobilecomponent/Navcasino/Navcasino'
import TVStremaing from './TVStremaing'
import Multimarket from "./Multimarket"
import InPlay from "./MobileInplay"
import FancyRules from './FancyRules'
import FooterPolicy from './policylink/FooterPolicy'
import FooterGtc from './policylink/FooterGtc'
import FooterRnr from './policylink/FooterRnr'
import Footerkyc from './policylink/FooterKyc'
import FooterResGaming from './policylink/FooterResGaming'
import FooterAboutus from './policylink/FooterAboutus'
import FooterSepolicy from './policylink/FooterSepolicy'
import FooterUpolicy from './policylink/FooterUpolicy'
import Home from './Home'
import CasinoModal from './CasinoModal'
import WinnerMarket from './WinnerMarket'
import LoadingOverlay from "./components/loading-overlay/loading-overlay";

import AppWrapper from ".././component/app-wrapper/app-wrapper";

import CombineWrapper from "../component/combine-wrapper/combine-wrapper";
// const CombineWrapper = React.lazy(() => import("../component/combine-wrapper/combine-wrapper"));

import { Helmet } from "react-helmet";
import {getCookies, getId, getToken, useGetUserDetails} from "./utils";

const cookies = new Cookies();
window.flag = 0;
var cc;
var tt;
toast.configure()

export default function Combine(props) {
  var eventId = null;
  var marketId = null;
  var eventT = 3;
  if (props.match.params.eventT === '4' || props.match.params.eventT === '1' || props.match.params.eventT === '2' || props.match.params.eventT === '7522' || props.match.params.eventT === '2378962' || props.match.params.eventT === '138') {
    eventId = props.match.params.eventId;
    marketId = props.match.params.marketId;
    eventT = 0;
  }
  if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'sports') {
    eventT = 1;
  } else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'inplay') {
    eventT = 2;
  } else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'home') {
    eventT = 3;
  } else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'multimarket') {
    eventT = 4;
  } else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'myaccount') {
    eventT = 5;
  } else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'WinnerMarket') {
    eventT = 9;
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'Fairhorsefullmarket') {
    eventT = 10;
  } else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'CasinoList') {
    eventT = 11;
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'activitylog') {
    eventT = 12;
  }

  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'mybets') {
    eventT = 13;
  }
  // desktop path
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'myprofile') {
    eventT = 'myprofile';
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'balance') {
    eventT = 'balance';
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'AccountStatement') {
    eventT = 'AccountStatement';
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'mybets') {
    eventT = 'mybets';
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'bethistory') {
    eventT = 'bethistory';
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'ProfitLoss') {
    eventT = 'ProfitLoss';
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'play' && props.match.params.belowType === 'activitylog') {
    eventT = 'activitylog';
  }

  const [SHowLogin, setSHowLogin] = useState(false)
  const [showFullMarket, setFullMarket] = useState(false);
  const [tabMenu, setTabMenu] = useState(eventT);
  const [countCricket, setCountCricket] = useState(0);
  const [countSoccer, setCountSoccer] = useState(0);
  const [countTennis, setCountTennis] = useState(0);
  const [open, setOpen] = useState(false);
  const [ShowPassModel, setShowPassModel] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [isError, setIsError] = useState(false);
  const [userid, setId] = useState("");
  const [password, setPassword] = useState("");
  const [captchaSuccess, setCaptcha] = useState(false);
  const [refreshhh, setrefreshhh] = useState(false);
  const [balance, setBalance] = useState(0);
  const [exposure, setExposure] = useState(0);
  const [userIDname, setuserIDname] = useState(false)
  const [passPass, setpassPass] = useState(false)
  const [newOpen, setnewOpen] = useState(false);
  const [expoSu, setexpoSu] = useState(false)
  const [refreshMsg, setrefreshMsg] = useState('Loading...')
  const [balan, setbalan] = useState(false)
  const [accOpen, setaccOpen] = useState(false)
  const [Tvopen, setTvopen] = useState(true)
  const [openLeftside, setopenLeftside] = useState(false)
  const [openRightside, setopenRightside] = useState(false)
  const [FancyRulespopup, setFancyRulespopup] = useState(false)
  const [CasinoPopup, setCasinoPopup] = useState(false)
  const [betSucess1, setbetSucess1] = useState(false)
  const [msgNumber, setMsgNumber] = useState(0);
  const [betInfo, setbetInfo] = useState({});
  const [stakeInfo, setstakeInfo] = useState({});
  const [isShown, setisShown] = useState(false);
  const [liveStream, setliveStream] = useState(null);
  const [PrivacyPolicy, setPrivacyPolicy] = useState(false);
  const [TermnCo, setTermnCo] = useState(false);
  const [RnR, setRnR] = useState(false);
  const [kyc, setkyc] = useState(false);
  const [Resg, setResg] = useState(false);
  const [Abtus, setAbtus] = useState(false);
  const [SEP, setSEP] = useState(false);
  const [UApolcy, setUApolcy] = useState(false);
  const [openProvider, setopenProvider] = useState(null);
  const [ProviderName, setProviderName] = useState(null);
  const [ProviderType, setProviderType] = useState(null);
  const [ProviderCredit, setProviderCredit] = useState(null);
  const [gameCode, setgameCode] = useState(null);
  const [updated, setupdated] = useState(false);
  const [casinoWait, setcasinoWait] = useState(false);
  const [transactionState, settransactionState] = useState(false);
  const [transactionCode, settransactionCode] = useState('');
  const [AEBalance, setAEBalance] = useState(0);
  const [GEbalance, setGEbalance] = useState(0);
  const [REBalance, setREBalance] = useState(0);
  const [EZBalance, setEZBalance] = useState(0);
  const [JLBalance, setJLBalance] = useState(0);
  const [loading, setloading] = useState(false);
  // navcasino
  const [navbarcas, setnavbarcas] = useState(false);


  // modal
  const [casinoModal, setCasinoModal] = useState(false);

  const apiURLHandler = 'https://luckybdt.live/clientapi/';


  const navcasinoclick = () => {
    setnavbarcas(!navbarcas)
  }

  const changeLiveStreaming = (val) => {
    setliveStream(val);
    setTvopen(true);
  }

  const NewsClick = () => {
    setnewOpen(!newOpen)
  }

  const handlepassmodel = () => {
    setShowPassModel(true)
  }
  const updatepropsTransaction = (openProvider) => {
    settransactionCode(openProvider)
    settransactionState(true)

  }

  const handlepolicy = (val1, val2) => {
    if (val1 === 1) {
      setPrivacyPolicy(val2);
    }
    if (val1 === 2) {
      setTermnCo(val2)
    }
    if (val1 === 3) {
      setRnR(val2)
    }
    if (val1 === 4) {
      setkyc(val2)
    }
    if (val1 === 5) {
      setResg(val2)
    }
    if (val1 === 6) {
      setAbtus(val2)
    }
    if (val1 === 7) {
      setSEP(val2)
    }
    if (val1 === 8) {
      setUApolcy(val2)
    }
  }

  const handlerightside = () => {
    setopenRightside(true)
  }

  const handlerightsideclose = () => {
    setopenRightside(false)
  }

  const handleleftside = () => {
    setopenLeftside(true)
  }

  const handleleftsideclose = () => {
    setopenLeftside(false)
  }


  const changeMsgNumber = (val) => {
    setMsgNumber(val);
  }

  const changeBetInfo = (obj) => {
    var obj2 = obj;
    setbetInfo(obj2);
  }

  const changeStakeInfo = (obj) => {
    var obj2 = obj;
    setstakeInfo(obj2);
  }


  const handlePass = (value) => {
    setpassPass(value)
  }

  const handlebal = (value) => {
    setbalan(value)
  }

  const handleExpo = (value) => {
    setexpoSu(value)
  }

  /* Update Balance api from vendor side start */
  const doUpdateBalance = async (username, mainBalance, amountToBeUpdate, casinoType, token) => {
    try {
      let response = await axios({
        method: 'POST',
        url: apiURLHandler + 'update_balance',
        data: {
          id: username,
          LimitCurr: mainBalance,
          casino_value: amountToBeUpdate,
          casino_type: casinoType,
          token
        }
      });
      if (response.data) {
        // console.log('Balance updated successfully!');
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  /* Update Balance api from vendor side end */

  /* Get Balance status from vendor side api start */
  const getBalance = async (username, password, providercode, mainBalance, token, refreshBalanceGameAmount) => {
    try {
      let response = await axios({
        method: 'POST',
        url: apiURLHandler + 'getBalance',
        data: {
          username,
          password,
          providercode
        }
      });
      if (response.data.status == true) {
        let value = response.data.data.balance;

        if (providercode === "GE") {
          let casinoTypeCode = 'ge_credit';
          // setGEFund(value)
          // localStorage.setItem('ge_credit', value);
          setGEbalance(value)
          localStorage.setItem('transactionState', false);


          // settransactionState(false)
          // settransactionCode('')


          localStorage.setItem('transactionCode', '')
          // update balance from vendor api
          // Check if both api balance is same
          // Do update only if not same
          if (String(value) !== String(refreshBalanceGameAmount)) {
            doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
          }
        }

        if (providercode === "S2") {
          let casinoTypeCode = 'awc_credit';
          // setAWCFund(value)
          // localStorage.setItem('awc_credit', value);
          setAEBalance(value)
          localStorage.setItem('transactionState', false);
          localStorage.setItem('transactionCode', '');


          // settransactionState(false)
          // settransactionCode('')


          // update balance from vendor api
          // Check if both api balance is same
          // Do update only if not same
          if (String(value) !== String(refreshBalanceGameAmount)) {
            doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
          }
        }

        if (providercode === "RE") {
          let casinoTypeCode = 'kingmaker_credit';
          // setAWCFund(value)
          setREBalance(value)
          localStorage.setItem('transactionState', false);
          localStorage.setItem('transactionCode', '');


          // settransactionState(false)
          // settransactionCode('')


          // update balance from vendor api
          // Check if both api balance is same
          // Do update only if not same
          if (String(value) !== String(refreshBalanceGameAmount)) {
            doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
          }
        }

        if (providercode === "JL") {
          let casinoTypeCode = 'jili_credit';
          // setAWCFund(value)
          setJLBalance(value)
          localStorage.setItem('transactionState', false);
          localStorage.setItem('transactionCode', '');


          // settransactionState(false)
          // settransactionCode('')


          // update balance from vendor api
          // Check if both api balance is same
          // Do update only if not same
          if (String(value) !== String(refreshBalanceGameAmount)) {
            doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
          }
        }

        if (providercode === "EZ") {
          let casinoTypeCode = 'kingmaker_credit';
          // setAWCFund(value)
          setEZBalance(value)
          localStorage.setItem('transactionState', false);
          localStorage.setItem('transactionCode', '');


          // settransactionState(false)
          // settransactionCode('')


          // update balance from vendor api
          // Check if both api balance is same
          // Do update only if not same
          if (String(value) !== String(refreshBalanceGameAmount)) {
            doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
          }
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  /* Get Balance status from vendor side api end */

  useEffect(() => {
    setTabMenu(eventT);

  }, [eventT]);


  // check login

  useEffect(() => {
    if (getCookies('token') == null) {
      setLoggedIn(false);
      return;
    } else {
      var utoken = getCookies('token');
      tt = utoken;
      var user = getCookies('id');
      // var password = cookies.get('password');


      setId(user);
      // setPassword(password);

      if (user && utoken) {
        setLoggedIn(true);
      }


      // axios.post('https://luckybdt.live/clientapi/refresh_balance', {
      //     id: user,
      //     token: utoken
      // }).then(async result => {

      //   if (result.status === 210) {
      //     // console.log('refresh successful');
      //     setLoggedIn(true);
      //     setBalance(result.data[0].mainBalance - Math.abs(result.data[0].exposureBalance));
      //     // localStorage.setItem('mainBalance', result.data[0].mainBalance);
      //       setEZBalance(result.data[0].ezugi_credit);
      //       setREBalance(result.data[0].kingmaker_credit);
      //       setJLBalance(result.data[0].jili_credit);
      //       setGEbalance(result.data[0].ge_credit);
      //       setAEBalance(result.data[0].awc_credit);

      //       let getTransactionState = transactionState;
      //       let getTransactionCode = transactionCode;

      //     if (getTransactionState === null || getTransactionState === undefined) {
      //       localStorage.setItem('transactionState', false);
      //       // settransactionState(false)
      //       await getBalance(user, password, 'GE', result.data[0].mainBalance, utoken, result.data[0].ge_credit);

      //     }

      //     if (getTransactionCode === null || getTransactionCode === undefined) {
      //       localStorage.setItem('transactionCode', '');
      //       // settransactionCode('')
      //     }

      //     if (result.data[0].exposureBalance) {
      //         setExposure(Math.abs(result.data[0].exposureBalance));
      //     } else {
      //         setExposure(0);
      //     }
      //   }

      //   if (result.status === 220) {
      //     // console.log('refresh unsucessful');
      //     setLoggedIn(false);
      //   }
      // }).catch(e => {
      //     setIsError(true);
      // });

    }
  }, []);



  const changeState = (obj) => {
    var obj1 = true;
    obj1 = obj;
    setFullMarket(obj1);
    changeTab(0);
  };

  const changeExposure = (obj) => {
    setExposure(obj);
  }

  const changeBalance = (obj) => {
    setBalance(obj);
  }

  const changeTab = (val) => {
    setTabMenu(val);
  }
  const checkShowLogin = (bool) => {
    setSHowLogin(bool);
  }

  const handleLogout = () => {
    cookies.remove('id', { path: '/' });
    cookies.remove('token', { path: '/' });
    setTabMenu(3);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  const handleRefreshhh = (t = 1000, msg = 'Loading...', flag = 0) => {
    if (t === false) {
      setrefreshhh(false);
      setrefreshMsg('');
    } else {
      if (flag === 1) {
        setrefreshhh(true);
        setrefreshMsg(msg);
      } else if (flag === 2) {
        setrefreshhh(false);
      } else {
        setrefreshhh(true);
        setrefreshMsg(msg)
        setTimeout(() => {
          setrefreshhh(false);
        }, t);
      }
    }
  }

  const ChangeTv = () => {
    setTvopen(!Tvopen)
    TvControl();
  }
  const ChangeSetTv = () => {
    setTvopen(false)
  }

  const openLogin = () => {
    if (!isLoggedIn) {
      checkShowLogin(true);
      return;
    }
  }

  const handleCasinoBal = (provider, providertype, providerName, providerCredit, gameCode) => {
    openLogin();
    setCasinoModal(true);
    setCasinoPopup(true);
    setopenProvider(provider);
    setProviderType(providertype);
    setProviderName(providerName);
    setProviderCredit(providerCredit);
    setgameCode(gameCode);
  }

  // const handleCasinoBalfalse = () => {
  //   setCasinoPopup(false)
  // }

  const hnadlefancyrules = () => {
    setFancyRulespopup(true)
  }

  const closefancyrules = () => {
    setFancyRulespopup(false)
  }

  const TvControl = () => {
    setisShown(true);
    setTimeout(() => {
      setisShown(false)
    }, 2700);
  }

  return (
    <CombineWrapper
      isLoading={loading}
      desktopComponent={(
        <>
          {/*<Helmet>*/}
          {/*  <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"/>*/}
          {/*</Helmet>*/}

          <div style={{
            height: '2rem',
            width: '100%',
            backgroundColor: 'black',
            // position: 'fixed',
            // top: 0
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #dddddd'
          }}>
            <a href="/exchange/play/myaccount" style={{ color: '#FFB80C', fontSize: '1.3rem', marginLeft: '1rem' }}>&#60; GO BACK</a>
          </div>
          <div style={{
            // height: '98vh',
            position: 'relative'
          }}>
            {tabMenu === 'myprofile' && (
              <Myprofile userid={userid} handlepassmodel={handlepassmodel} />
            )}
            {tabMenu === 'balance' && (
              <Balanceoverview userid={userid} utoken={tt} balance={balance} handlepassmodel={handlepassmodel} />
            )}
            {tabMenu === 'AccountStatement' && (
              <AccountStatement
                userid={userid}
                utoken={tt}
                handlepassmodel={handlepassmodel}
              />
            )}
            {tabMenu === 'mybets' && <Mybets userid={userid} utoken={tt} handlepassmodel={handlepassmodel} />}
            {tabMenu === 'bethistory' && (
              <Bethistory userid={userid} utoken={tt} handlepassmodel={handlepassmodel} />
            )}
            {tabMenu === 'ProfitLoss' && (
              <ProfitLoss userid={userid} utoken={tt} handlepassmodel={handlepassmodel} />
            )}
            {/* {tabMenu === 25 && (
                    <CasinoReport userid={userid} utoken={tt} handlepassmodel={handlepassmodel} />
                  )} */}
            {tabMenu === 'activitylog' && <ActivityLog userid={userid} handlepassmodel={handlepassmodel} />}
          </div>
        </>
      )}
      mobileComponent={(
        <>
          {/*<Helmet>*/}
          {/*  <meta name="viewport" content="width=device-width, initial-scale=1"/>*/}
          {/*</Helmet>*/}
          {SHowLogin && <Loginmobile checkShowLogin={checkShowLogin} handlepolicy={handlepolicy} />}

          {!SHowLogin && <Header Tvopen={Tvopen} setloading={setloading} loading={loading} AEBalance={AEBalance}
            setAEBalance={setAEBalance} setEZBalance={setEZBalance}
            setREBalance={setREBalance} setJLBalance={setJLBalance}
            setGEbalance={setGEbalance} EZBalance={EZBalance} REBalance={REBalance}
            JLBalance={JLBalance} GEbalance={GEbalance} updated={updated}
            setupdated={setupdated} handleRefreshhh={handleRefreshhh} liveStream={liveStream}
            stakeInfo={stakeInfo} betInfo={betInfo} msgNumber={msgNumber}
            changeMsgNumber={changeMsgNumber} refreshMsg={refreshMsg} ChangeTv={ChangeTv}
            changeExposure={changeExposure} changeBalance={changeBalance}
            refreshhh={refreshhh} tabMenu={tabMenu} userid={userid} utoken={tt}
            exposure={exposure} balance={balance} openLeftside={openLeftside}
            openRightside={openRightside} handlerightside={handlerightside}
            handlerightsideclose={handlerightsideclose}
            handleleftsideclose={handleleftsideclose} handleleftside={handleleftside}
            isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} checkShowLogin={checkShowLogin}
            handleLogout={handleLogout} />}

          {!SHowLogin && isLoggedIn === true && <ANnouncement NewsClick={NewsClick} userid={userid} utoken={tt} />}
          {/* {!SHowLogin && Tvopen === true && tabMenu ===0 && isLoggedIn === true && liveStream && liveStream != '' &&<TVStremaing liveStream = {liveStream} isShown={isShown} TvControl={TvControl} ChangeSetTv={ChangeSetTv} Tvopen={Tvopen}/>} */}

          {/*{!SHowLogin && (tabMenu === 1 || tabMenu === 3) && <Carousel />}*/}

          {/* {!SHowLogin && isLoggedIn === true && (tabMenu === 3 || tabMenu === 6 || tabMenu === 7 || tabMenu === 9 || tabMenu === 10 || tabMenu === 11 || tabMenu === 12 || tabMenu === 13 || tabMenu === 14) && <CasinoCarousel/>} */}
          {!SHowLogin && (tabMenu === 1 || (tabMenu === 5 && !isLoggedIn)) &&
            <Highlights handlepolicy={handlepolicy} isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin}
              handlepolicy={handlepolicy} countTennis={countTennis} countSoccer={countSoccer}
              countCricket={countCricket} showFullMarket={showFullMarket} changeState={changeState} />}


          {!SHowLogin && (tabMenu === 3) &&
            <Home openLogin={openLogin} AEBalance={AEBalance} EZBalance={EZBalance} REBalance={REBalance}
              GEbalance={GEbalance} JLBalance={JLBalance} casinoWait={casinoWait}
              handleCasinoBal={handleCasinoBal} isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} />}

          {/* {!SHowLogin && (tabMenu === 11) && <CasinoList isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} />} */}
          {!navbarcas === false &&
            <Navcasino isLoggedIn={isLoggedIn} navbarcas={navbarcas} navcasinoclick={navcasinoclick} />}
          {!SHowLogin && tabMenu === 0 &&
            <Newfullmarket liveStream={liveStream} isShown={isShown} TvControl={TvControl}
              ChangeSetTv={ChangeSetTv} Tvopen={Tvopen} changeLiveStreaming={changeLiveStreaming}
              isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin}
              changeStakeInfo={changeStakeInfo} changeBetInfo={changeBetInfo}
              changeMsgNumber={changeMsgNumber} userid={userid} utoken={tt}
              handleRefreshhh={handleRefreshhh} hnadlefancyrules={hnadlefancyrules} />}

          {!SHowLogin && tabMenu === 9 && <WinnerMarket />}

          {!SHowLogin && tabMenu === 2 &&
            <InPlay isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} changeState={changeState}
              userid={userid} utoken={tt} handleRefreshhh={handleRefreshhh} />}

          {!SHowLogin && tabMenu === 4 &&
            <Multimarket isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} changeState={changeState}
              userid={userid} utoken={tt} handleRefreshhh={handleRefreshhh} />}

          {!SHowLogin && <Footer navbarcas={navbarcas} navcasinoclick={navcasinoclick} isLoggedIn={isLoggedIn}
            changeTab={changeTab} checkShowLogin={checkShowLogin} tabMenu={tabMenu} />}
          {!SHowLogin && tabMenu === 5 && isLoggedIn === true &&
            <Account userid={userid} handleLogout={handleLogout} />}
          {!SHowLogin && tabMenu === 12 && <Activitylog />}




          {FancyRulespopup === true && <FancyRules closefancyrules={closefancyrules} />}
          {PrivacyPolicy === true && <FooterPolicy handlepolicy={handlepolicy} />}
          {TermnCo === true && <FooterGtc handlepolicy={handlepolicy} />}
          {RnR === true && <FooterRnr handlepolicy={handlepolicy} />}
          {kyc === true && <Footerkyc handlepolicy={handlepolicy} />}
          {Resg === true && <FooterResGaming handlepolicy={handlepolicy} />}
          {Abtus === true && <FooterAboutus handlepolicy={handlepolicy} />}
          {SEP === true && <FooterSepolicy handlepolicy={handlepolicy} />}
          {UApolcy === true && <FooterUpolicy handlepolicy={handlepolicy} />}
        </>
      )}
    >
    </CombineWrapper>
  )
}
