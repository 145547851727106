import React from 'react'
import Soccerjpg from '../images/kv/KV02.jpg';
import SoccerMatches from './SoccerMatches';
const axios = require("axios");


export default function Soccer(props) {
  return (
    <div _ngcontent-njs-c61  >

      <div _ngcontent-njs-c61  >
        <div _ngcontent-njs-c61 class="images-grid">
          <img _ngcontent-njs-c61 src={Soccerjpg} alt="" />
        </div>
        <div _ngcontent-njs-c61 id="gameHighlightWrap" style={{ marginBottom: '0' }} class="highlight-edge-content col3">
          <h3 _ngcontent-njs-c61 style={{ textAlign: 'left' }}>Sports Highlights
          <div class="highlight-sorting">
						<label for="lang">View by</label>
						<div class="select">
							<select id="viewType" name="View" >
								<option id="competitionName" value="competitionName" orderby="asc" priority="asc">Competition</option>
								<option id="openDateTime" value="openDateTime" orderby="asc" inplay="desc" selected="">Time</option>
								<option id="totalMatched" value="totalMatched" orderby="desc">Matched</option>
							</select>
						</div>
					</div> </h3>
          <ul _ngcontent-njs-c61 class="sports-header">
          <li _ngcontent-njs-c61 class="col-game" style={{ width: 'calc(64% - 38px)' }}></li>
          <li _ngcontent-njs-c61 class="sport-odds">1</li>
          <li _ngcontent-njs-c61 class="col-draw">x</li>
          <li _ngcontent-njs-c61 class="last-col">2</li>
          <li _ngcontent-njs-c61 class="info-icon"></li>
        </ul>
        <SoccerMatches changeState={props.changeState} />
        </div>
      
      </div>
    </div>
  )
}
