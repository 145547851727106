import stringSimilarity from "string-similarity";
import Cookies from "universal-cookie";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import {useEffect, useState} from "react";
const cookies = new Cookies();
export const marketKeys = {
    matchOdds: "matchOdds",
    bookmaker: "bookmaker",
    winToss: "winToss",
    superOver: "superOver",
    tiedMatch: "tiedMatch",
    completedMatch: "completedMatch",
    moneyLine: "moneyLine",
    fancy: "fancy",
    premium: "premium",
    overUnderGoals: (val) => `Over/Under ${val} Goals`
}

export const soketIoMarketKey = {
    matchOdds: "betfair_match_rate",
    bookmaker: "bookmaker_match_rate",
    winToss: "toss_match_rate",
    superOver: "Super_over_match",
    tiedMatch: "Tied_Match_Rate",
    completedMatch: "Completed_Match_rate",
    fancy: "session_rate",
    moneyLine: "betfair_match_rate",
    premium: "premium_Rate",
    miniBookMaker: "minibookmaker_match_rate",
    lunchFavorite: 'lunchFavorite_match_rate',
    [marketKeys.overUnderGoals('0.5')]: 'betfair_match_rate05',
    [marketKeys.overUnderGoals('1.5')]: 'betfair_match_rate15',
    [marketKeys.overUnderGoals('2.5')]: 'betfair_match_rate25',
    [marketKeys.overUnderGoals('3.5')]: 'betfair_match_rate35',
    [marketKeys.overUnderGoals('4.5')]: 'betfair_match_rate45',
}

export const soketEndPoint = () => {
    const ranNum = Math.floor(Math.random() * 4) + 1;
    let ENDPOINT;
    if (ranNum == 1) {
        ENDPOINT = "https://luckybdt.live:2053";
    } else if (ranNum == 2) {
        ENDPOINT = "https://luckybdt.live:2083";
    } else if (ranNum == 3) {
        ENDPOINT = "https://luckybdt.live:2087";
    } else {
        ENDPOINT = "https://luckybdt.live:2096";
    }
    return ENDPOINT;
}


export const checkStrVsStr = (str1, str2, matchRate) => stringSimilarity.findBestMatch(str1, str2).bestMatch.rating > (matchRate ? matchRate : 0.42);



// premium bet cal
// const updatedBetRecordsData = [{"id":1972623602,"apiSite":2,"apiSiteSelectionId":"13","eventId":2997874,"eventType":1,"betfairEventId":32692091,"marketId":"417497487","selectionName":"under 2.5","odds":1.7,"isActive":1,"handicap":0,"updateDate":1698522947282,"oddsUpdateDate":1698528113079,"profitLoss":0,"betCounter":[]},{"id":1972623601,"apiSite":2,"apiSiteSelectionId":"12","eventId":2997874,"eventType":1,"betfairEventId":32692091,"marketId":"417497487","selectionName":"over 2.5","odds":2.1,"isActive":1,"handicap":0,"updateDate":1698522947282,"oddsUpdateDate":1698528113079,"profitLoss":0}]
//



export const exposurePremiumLogic = ({ updatedBetRecordsData = [], teamName = null, clientId = '' }) => {
    // const teamName = 1972623602;
    // const clientId = 'tom';
    let userBets = {};  // Assuming userBets is an object.
    let userBetCount = {}; // Assuming userBetCount is an object.
    let coin = 0;  // Assuming a value, replace it with the correct value.
    let odds = 0;  // Assuming a value, replace it with the correct value.


    const adjustedOdds = odds - 1;
    const isFirstBet = updatedBetRecordsData.length === 0;
    const matchedBet = updatedBetRecordsData.find(b => b.id === teamName && b.isActive === 1);
    userBetCount[clientId] = (userBetCount[clientId] || 0) + 1;

    if (isFirstBet === false) {
        if (matchedBet) {
            matchedBet.profitLoss += coin * adjustedOdds;  // Changed assignment to addition
            matchedBet.betCounter = [userBetCount[clientId]];
        }
        for (let b of updatedBetRecordsData) {
            if (b.id !== teamName && b.isActive === 1) {
                b.profitLoss -= coin;  // Subtracting the coin directly as it's a loss
            }
        }
    } else {
        if (matchedBet) {
            matchedBet.profitLoss += coin * adjustedOdds;  // Keeping addition here as well
            if (matchedBet.betCounter) {
                matchedBet.betCounter.push(userBetCount[clientId]);
            } else {
                matchedBet.betCounter = [userBetCount[clientId]];
            }
        }
        for (let b of updatedBetRecordsData) {
            if (b.id !== teamName && b.isActive === 1) {
                b.profitLoss -= coin;  // Subtracting the coin directly as it's a loss
            }
        }
    }

    const recordUserBet = (clientId, bet) => {
        if (!userBets[clientId]) {
            userBets[clientId] = [];
        }
        userBets[clientId].push(bet);
    }

    recordUserBet(clientId, matchedBet); // Added matchedBet as an argument
    return updatedBetRecordsData;
    // return {profitLoss: updatedBetRecordsData.profitLoss, marketId: updatedBetRecordsData.marketId, eventId: updatedBetRecordsData.betfairEventId,
    //     teamName: updatedBetRecordsData?.id,
    // };
}

// const result = exposureLogic({updatedBetRecordsData, teamName, clientId});
// console.log('myresult: ', result);


export const pushAndUpdateReUseArrListObjValue = ({ arr, id, key, value, push, updateAt }) => {
    const userIsExist = arr.filter((item) => item.id === id);
    const objIndex = arr.findIndex(item => item.id === id);

    if (userIsExist.length === 0) {
        push({ id, [key]: value })
    } else {
        updateAt(objIndex, { ...userIsExist[0], [key]: value })
    }
}


export const fixValue = (value, getType) => {
    if (getType === 'string') {
        return typeof value === "string" ? value : JSON.stringify(value)
    }
    if (getType === 'number') {
        return typeof value === "number" ? value : parseFloat(value)
    }
    return value;
}

export const getCookies = (name) => {
    const cookie = cookies.get(name);
    return cookie;
}

export const removeCookies = (name) => {
    cookies.remove(name, { path: '/' });
}

export const setCookies = (name, value) => {
    cookies.set(name, value, { path: '/' });
}

export const useGetUserDetails = () => {
    // const { search } = useLocation();
    const history = useHistory();
    // const searchParams = queryString.parse(search);
    const [userDetails, setUserDetails] = useState({id: '', token: '', type: '', queryString: ''});
    const tokenCoookie = ()=> cookies.get('token');
    const isCookieToken = tokenCoookie() && tokenCoookie() != null && tokenCoookie() != undefined;

    const idCoookie = ()=> cookies.get('id');
    const isIdCoookie = idCoookie() && idCoookie() != null && idCoookie() != undefined;
    const setDetails = (arg)=> setUserDetails({
        ...arg,
        ...userDetails,
    })


   const refreshCookies = ()=> {
       if (isCookieToken){
           setDetails({
               id: idCoookie(),
               token: tokenCoookie(),
               type: 'cookie',
           })
       }
   }
    useEffect(() => {
        refreshCookies();
    }, []);


    // else if (searchParams.t){
    //     userDetails.token = searchParams.t
    //     userDetails.type = 'query';
    //     setCookies('token', userDetails.token)
    // }

    // if (isIdCoookie){
    //     userDetails.id = idCoookie;
    // }

    // else if (searchParams.u){
    //     userDetails.token = searchParams.u
    //     setCookies('id', userDetails.id)
    // }

    // if(userDetails.type === 'query'){
    //     userDetails.queryString = `?t=${userDetails.token}&u=${userDetails.id}`
    // }

    const navigate = (url) => {
        let joinPathEnd = '';
        if(userDetails.type === 'query'){
            joinPathEnd = userDetails.queryString
        }
        history.push(url + joinPathEnd)
    }

    return {...userDetails, isCookieExist: isCookieToken && isIdCoookie, navigate, refreshCookies};
}
