import React, { useEffect, useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import Loading from "../images/sprite/loading40.gif"
import moment from 'moment'
require('moment-timezone');

export default function Tommorow(props) {


  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([])
  const [refresh, setrefresh] = useState(true);

  useEffect(() => {
    axios.get(
      `https://luckybdt.live/clientapi/cricket`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {

          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }

        });



        setcricket(obj);
      })
      .catch(error => console.log('error'));

    axios.get(
      `https://luckybdt.live/clientapi/tennis`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });

        setTennis(obj);
      })
      .catch(error => console.log('error'));

    axios.get(
      `https://luckybdt.live/clientapi/soccer`)
      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {
          var tommorow = moment().add(1, 'day');


          if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            obj.push(item);
          }


        });


        setSoccer(obj);
        setrefresh(false);
      })
      .catch(error => console.log('error'));

  }, [])




  return (
    <div>

      {refresh === true &&
        <div id="loading" class="loading-wrap" style={{ top: '100px' }}>
          <ul class="loading">
            <li><img src={Loading} /></li>
            <li>Loading...
            </li>
            <br />
            <li><span id="progress"></span></li>
          </ul>
        </div>}


      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0) && refresh === false && <div id="sportListWrap" class="sport_list-wrap">



        {cricket.length > 0 && <div class="game-wrap ng-scope">
          <h3 class="ng-binding">Cricket</h3>
          {cricket.map(function (item, id) {

            var showtommorow = false;


            var showFancy = false;
            var res = item.marketStartTime;

            if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
              showFancy = true;
            }


            var tommorow = moment().add(1, 'day');


            if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
              showtommorow = true;
            }


            return (
              <React.Fragment>

                {
                  showtommorow
                  &&
                  <dl _ngcontent-njs-c61 class="sportsgame-list-content ng-scope" key={id}>

                    <dt _ngcontent-njs-c61 id="openTime" class="time ng-binding">{res}</dt>

                    <dd _ngcontent-njs-c61 id="eventDetail" class="ng-binding">
                      {/*<img class="fromto" src={Transparent} alt="fromto"/>T20 Blast*/}
                      Cricket
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      --
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      <Link _ngcontent-njs-c61 to={`/exchange/play/fullmarket/4/${item.eventId}/${item.marketId}`} id="vsNameClick" onClick={() => { props.changeState(true); }}>
                        <strong class="ng-binding">{item.eventName}</strong>
                      </Link>
                    </dd>
                  </dl>}
              </React.Fragment>
            )
          })}
        </div>}



        {Tennis.length > 0 && <div class="game-wrap ng-scope">
          <h3 _ngcontent-njs-c61 class="ng-binding">Tennis</h3>

          {Tennis.map(function (item, id) {

            var showtommorow = false;


            var showFancy = false;
            var res = item.marketStartTime;

            if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
              showFancy = true;
            }


            var tommorow = moment().add(1, 'day');


            if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
              showtommorow = true;
            }
            return (
              <React.Fragment>
                {showtommorow &&
                  <dl _ngcontent-njs-c61 class="sportsgame-list-content ng-scope" key={id} >
                    <dt _ngcontent-njs-c61 id="openTime" class="time ng-binding">{res}</dt>
                    <dd _ngcontent-njs-c61 id="eventDetail" class="ng-binding">
                      {/*<img class="fromto" src={Transparent} alt="fromto"/>ATP Vienna 2020*/}
                      Tennis
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      --
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      <Link to={`/exchange/play/fullmarket/2/${item.eventId}/${item.marketId}`} id="vsNameClick" onClick={() => { props.changeState(true); }}>
                        <strong _ngcontent-njs-c61 class="ng-binding">{item.eventName}</strong>
                      </Link>
                    </dd>

                  </dl>}
                {/* 
            {showtommorow===false && 
            <div id="noDataDiv" class="no-data">
                <p>There are no events to be displayed.</p>
             </div>
            
            }
          */}
              </React.Fragment>
            )
          })}


        </div>}


        {Soccer.length > 0 && <div class="game-wrap ng-scope">
          <h3 _ngcontent-njs-c61 class="ng-binding">Soccer</h3>

          {Soccer.map(function (item, id) {
            var showtommorow = false;


            var showFancy = false;
            var res = item.marketStartTime;

            if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
              showFancy = true;
            }


            var tommorow = moment().add(1, 'day');


            if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
              showtommorow = true;
            }

            return (
              <React.Fragment>
                {showtommorow &&
                  <dl _ngcontent-njs-c61 class="sportsgame-list-content ng-scope" key={id} >
                    <dt _ngcontent-njs-c61 id="openTime" class="time ng-binding">{res}</dt>
                    <dd _ngcontent-njs-c61 id="eventDetail" class="ng-binding">
                      {/*<img class="fromto" src={Transparent} alt="fromto"/>ATP Vienna 2020*/}
                      Soccer
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      --
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      <Link _ngcontent-njs-c61 to={`/exchange/play/fullmarket/1/${item.eventId}/${item.marketId}`} id="vsNameClick" onClick={() => { props.changeState(true); }}>
                        <strong _ngcontent-njs-c61 class="ng-binding">{item.eventName}</strong>
                      </Link>
                    </dd>
                  </dl>}

              </React.Fragment>
            )
          })}
        </div>}
      </div>}

      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0) && refresh === false && <div _ngcontent-njs-c61 id="noDataDiv" class="no-data">
        <p>There are no events to be displayed.</p>
      </div>}



    </div>
  )
}
