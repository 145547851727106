import React, { useState } from 'react'
import Cup from '../images/sprite/cup.gif'
import Transparent from '../images/sprite/transparent.gif'
import { Link } from "react-router-dom";
import { Homeannounce } from './Homeannounce'
import Promot from '../images/sprite/promot.gif'
export default function Footer(props) {

	const [footerfalse, setfooterfalse] = useState(false)

	const clickfooter = () => {
		setfooterfalse(!footerfalse)
	}

	return (
		<div _ngcontent-njs-c65="" >
			{/* <nav _ngcontent-njs-c65="" >
				<ul _ngcontent-njs-c65="" >
					<li _ngcontent-njs-c65=""  id="sports">
						<a _ngcontent-njs-c65=""  href='/exchange/play/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img _ngcontent-njs-c65=""  className="icon-sports" src={Cup} alt="gif" />Sports
						</a></li>
					<li _ngcontent-njs-c65=""  id="inPlay">
						<a _ngcontent-njs-c65=""  href='/exchange/play/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img _ngcontent-njs-c65=""  className="icon-inplay" src={Cup} alt="gif" />In-Play
						</a></li>
					<li _ngcontent-njs-c65=""  id="home" className={`main-nav ${(props.tabMenu === 3) ? "select" : "null"}`}>
						<a _ngcontent-njs-c65=""  href='/' className="ui-link">
							<img _ngcontent-njs-c65=""  className="icon-home" src={Cup} alt="gif" />Home</a></li>
					<li _ngcontent-njs-c65=""  id="multiMarket">
						<a _ngcontent-njs-c65="" href='/exchange/play/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img className="icon-pin" src={Cup} alt="gif" />Multi Markets
						</a></li>
					<li _ngcontent-njs-c65=""  id="account">
						{props.isLoggedIn && <Link to='/exchange/play/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>< img className="icon-account" src={Cup} alt="gif" />Account</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">< img className="icon-account" src={Cup} alt="gif" />Account</a>}
					</li>
				</ul>
			</nav> */}

			{/* <nav>
			<Homeannounce/>
				<ul class="mini-lobby">

					<li id="mini" className="main-nav">
						{props.isLoggedIn && <a onClick={() => { props.navcasinoclick(props.navbarcas) }} style={{ paddingTop: '4px' }} className="ui-link">
							<img className="icon-promote" src={Promot} alt="mini" />
						</a>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true) }} style={{ paddingTop: '4px' }} className="ui-link">
							<img className="icon-promote" src={Promot} alt="mini" />
						</a>}
					</li>



					<li id="sports">
						<Link _ngcontent-njs-c65="" to='/exchange/play/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img className="icon-sports" src={Transparent} />Sports
						</Link></li>


					<li id="inPlay">
						<Link _ngcontent-njs-c65="" to='/exchange/play/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img class="icon-inplay" src={Transparent} />In-Play
						</Link></li>



					<li _ngcontent-njs-c65="" id="home">
						<Link _ngcontent-njs-c65="" to='/' className={`ui-link ${(props.tabMenu === 3) ? "select" : "null"}`}>
							<img class="icon-home" src={Transparent} />Home
						</Link></li>



					<li id="multiMarket">
						<Link _ngcontent-njs-c65="" to='/exchange/play/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img class="icon-pin" src={Transparent} />Multi Markets
						</Link></li>
					<li id="account">
						{props.isLoggedIn && <Link to='/exchange/play/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>
							<img class="icon-account" src={Transparent} />Account
						</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">	<img class="icon-account" src={Transparent} />Account</a>}
					</li>
				</ul>
			</nav> */}
			<nav className="cls-footer-sec sk-footer">
				<ul style={{ marginBottom: '0px', justifyContent: 'center' }}>
					<li id="sports1" className={`main-nav ${(props.tabMenu === 1) ? "select" : "null"}`}>
						<Link to='/exchange/play/sports' className="ui-link loading_" style={{ color: '#fff', textDecoration: 'none' }}>
							<img src="https://www.playinexch.com/exchange/images/new_footer_icon/2.png" />Sports</Link>
					</li>
					<li id="inPlay" className={`main-nav ${(props.tabMenu === 2) ? "select" : "null"}`}>
						<Link to="/exchange/play/inplay" id="inPlay_open" className="ui-link   " style={{ display: "block", color: '#fff', textDecoration: 'none' }} >
							<img src="https://www.playinexch.com/exchange/images/new_footer_icon/4.png" />In-Play</Link>

					</li>
					<li id="home" className={`main-nav ${(props.tabMenu === 3) ? "select" : "null"}`}>
						<Link to='/' className="ui-link loading_" style={{ color: '#fff', textDecoration: 'none' }}>
							<img src="https://www.playinexch.com/exchange/images/new_footer_icon/3.png" />Home</Link>
					</li>
					<li id="Exch" className={`main-nav ${(props.tabMenu === 4) ? "select" : "null"}`}>
						<Link to='/exchange/play/multimarket' className="ui-link loading_" style={{ color: '#fff', textDecoration: 'none' }}>
							<img src="https://www.playinexch.com/exchange/images/new_footer_icon/UI_ICONS/3.png" style={{ filter: 'invert(1)' }} />Multi</Link>
					</li>



					<li id="account" className={`main-nav ${(props.tabMenu === 5) ? "select" : "null"}`}>
						{props.isLoggedIn && <Link to='/exchange/play/myaccount' className="ui-link" style={{ color: '#fff', textDecoration: 'none' }}>
							<img src="https://www.playinexch.com/exchange/images/new_footer_icon/5.png" />
							Account
						</Link>}
						{!props.isLoggedIn && <Link onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link" style={{ color: '#fff', textDecoration: 'none' }}>
							<img src="https://www.playinexch.com/exchange/images/new_footer_icon/5.png" />
							Account
						</Link>}

					</li>
				</ul>
			</nav>


		</div >
	)
}
