import React, { memo, useEffect, useState} from "react";
import useBreakpoint from "../hooks/breakpoints";
import {useWindowSize} from 'react-use';

// hook
export const useMobileSize = () =>{
    const breakpoint = useBreakpoint();
    const [isMobileSize, setIsMobileSize] = useState(false);
    const [width, setWidth] = useState('100%');
    const [offSet, setOffset] = useState({ left: 0, right: 0});
    const {width: windowWidth} = useWindowSize();


    let mobileElement = document.getElementById('mobileMainWrapper');
    useEffect(()=>{
        // mobileElement =  document.getElementById('mobileMainWrapper');
        setOffset({
            left: mobileElement?.offsetLeft,
            right: 0
        })
    },[windowWidth, mobileElement]);

    useEffect(()=> {
        if(breakpoint === 'XS' && breakpoint !== 'SM'){
            setIsMobileSize(true);
            setWidth('100%');
        }
        if(breakpoint === 'MD' || breakpoint === 'SM'){
            setIsMobileSize(false);
            setWidth('27rem');
        }
        if(breakpoint === 'LG'
            // || breakpoint === 'XL' || breakpoint === 'XXL'
        ){
            setIsMobileSize(false);
            setWidth('28rem');
        }
        if(breakpoint === 'XL' || breakpoint === 'XXL'
        ){
            setIsMobileSize(false);
            setWidth('31rem');
        }
        console.log('breakpoint',breakpoint)
    },[breakpoint])

    return {isMobileSize, mobileWidth: width, offSet};
}

export const MobileBox = memo(({ children, className, style, id}) => {
    const { mobileWidth } = useMobileSize();
    const boxId = id ? { id } : {};


    return (
        <div
        className={className}
        style={{
            width: mobileWidth,
            margin: 'auto',
            ...style
        }}
        {...boxId}
        >
            {children}
        </div>
    )
})





// (
//     // <Row className={`justify-content-center align-items-center m-0 ${className}`} style={{...style}}>
//     //   <Col sm={8} md={6} lg={5} xl={4} xxl={3} className="p-0">


//     //   </Col>
//     // </Row>
// )

